<template>
  <div style="margin: 10px">
    <div class="text-center" >
      <h3>Selecciona Los Agravios De Tu Caso</h3>
    </div>
    <form>
      <div class="row justify-content-md-center">
        <div class="col-12 text-center">
          <br />
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioPrimero.$model"
              name="agravioPrimero"
              id="agravioPrimero"
              value="1"
              
            />
            <label title=" RECIBIR LA VOTACIÓN POR PERSONAS U ÓRGANOS DISTINTOS A LOS FACULTADOS POR LA LEY" class="form-check-label" for="agravioPrimero">
              Agravio Primero <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioSegundo.$model"
              name="agravioSegundo"
              id="agravioSegundo"
              value="1"
            />
            <label title="HABER MEDIADO DOLO O ERROR EN LA COMPUTACIÓN DE LOS VOTOS YSIEMPRE QUE ELLO SEA DETERMINANTE PARA EL RESULTADO DE LA VOTACIÓN" class="form-check-label" for="agravioSegundo">
              Agravio Segundo <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioTercero.$model"
              name="agravioTercero"
              id="agravioTercero"
              value="1"
            />
            <label class="form-check-label" for="agravioTercero" title=" HABER IMPEDIDO EL ACCESO DE LOS REPRESENTANTES DE PARTIDOS POLÍTICOS O HABERLOS EXPULSADO, SIN CAUSA JUSTIFICADA.">
              Agravio Tercero <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioCuarto.$model"
              name="agravioCuarto"
              id="agravioCuarto"
              value="1"
            />
            <label class="form-check-label" for="agravioCuarto" title="HABER EJERCIDO VIOLENCIA O PRESIÓN SOBRE LOS MIEMBROS DE LA MESA DIRECTIVA DE CASILLA O SOBRE LOS ELECTORES Y SIEMPRE QUE ESTOS HECHOS SEAN DETERMINANTES PARA EL RESULTADO DE LA VOTACIÓN.">
              Agravio Cuarto <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioQuinto.$model"
              name="agravioQuinto"
              id="agravioQuinto"
              value="1"
            />
            <label class="form-check-label" for="agravioQuinto" title="IMPEDIR, SIN CAUSA JUSTIFICADA, EL EJERCICIO DEL DERECHO DE VOTO A LOS CIUDADANOS Y ESTO SEA DETERMINANTE PARA EL RESULTADO DE LA VOTACIÓN">
              Agravio Quinto <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="$v.form.agravioSexto.$model"
              name="agravioSexto"
              id="agravioSexto"
              value="1"
            />
            <label class="form-check-label" for="agravioSexto" title="ERROR O DOLO DE ESCRUTINIO Y CÓMPUTO QUE, EN FORMA EVIDENTE, PONGAN EN DUDA LA CERTEZA DE LA VOTACIÓN Y SEAN DETERMINANTES PARA EL RESULTADO DE LA MISMA">
              Agravio Sexto  <sup style="color:#af52ed;">?</sup>
            </label>
          </div>

        </div>
      </div>


      <div class="mt-5"> 
        <div class="row" v-if="form.agravioPrimero == 1">
          <div class="card mx-auto col-11">
            <div class="card-header">
               <b>PRIMERO.- </b> RECIBIR LA VOTACIÓN PERSONAS U ÓRGANOS DISTINTOS A LOS FACULTADOS POR LA LEY, ARTÍCULO 75
            </div>

            <div class="card-body">
              <h5 class="card-title"></h5>
              <div class="row" v-for="(contadorEntradasA1,i) in contadorEntradasA1" :key="i">
                <div class="mb-3 col-lg-2 col-xs-12">
                  <label for="primeroSeccion" class="form-label" aria-label="primeroSeccion">
                    SECCION*</label
                  >
                  <input
                    type="text"
                    id="primeroSeccion"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoPrimero[i].seccion"
                    aria-label="Numero Seccion"
                  />
                </div>
                <div class="mb-3 col-lg-2 col-xs-12">
                  <label for="primeroCasilla" class="form-label" aria-label="primeroCasilla">
                    NUMERO DE CASILLA*</label
                  >
                  <input
                    type="text"
                    id="primeroCasilla"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoPrimero[i].numCasilla"
                    aria-label="Numero Casilla"
                  />
                </div>

                <div class="mb-3 col-lg-4 col-xs-12">
                  <label for="primeroFuncionario" class="form-label" aria-label="primeroFuncionario">
                    NOMBRE FUNCIONARIO EN EL ENCARTE OFICIAL*</label
                  >
                  <input
                    type="text"
                    id="primeroFuncionario"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoPrimero[i].nombreEncarte"
                    aria-label="Nombre Funcionario"
                  />
                </div>

                <div class="mb-3 col-lg-4 col-xs-12">
                  <label for="primeroReportado" class="form-label" aria-label="primeroReportado">
                    NOMBRE FUNCIONARIO REPORTADO*</label
                  >
                  <input
                    type="text"
                    id="primeroReportado"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoPrimero[i].nombreReportado"
                    aria-label="Nombre Funcionario Reportado"
                  />
                </div>
              </div>
              <div class="text-center">
                  <a v-if="contadorEntradasA1>1" class="btn btn-primary my-4" @click="eliminarEntrada(1)">-</a>
                  <a class="btn btn-primary my-4 mx-2" @click="agregarEntrada(1)">+</a>
                </div>
            </div>

          </div>
        </div>

        <div class="row" v-if="form.agravioSegundo == 1">
          <div class="card mx-auto col-11"> 
            <div class="card-header">
               <b>SEGUNDO.- </b> HABER MEDIADO DOLO O ERROR EN LA COMPUTACIÓN DE LOS VOTOS Y SIEMPRE QUE ELLO SEA DETERMINANTE PARA EL RESULTADO DE LA VOTACIÓN
            </div>

            <div class="card-body">
              <h5 class="card-title"></h5>
              <div class="row" v-for="(contadorEntradasA2,i) in contadorEntradasA2" :key="i">
                <div class="mb-3 col-lg-2 col-xs-12">
                  <label for="primeroSeccion" class="form-label" aria-label="primeroSeccion">
                    SECCION*</label
                  >
                  <input
                    type="text"
                    id="primeroSeccion"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoSegundo[i].seccion"
                    aria-label="Numero Seccion"
                  />
                </div>
                <div class="mb-3 col-lg-2 col-xs-12">
                  <label for="primeroCasilla" class="form-label" aria-label="primeroCasilla">
                    NUMERO DE CASILLA*</label
                  >
                  <input
                    type="text"
                    id="primeroCasilla"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoSegundo[i].numCasilla"
                    aria-label="Numero Casilla"
                  />
                </div>

                <div class="mb-3 col-lg-4 col-xs-12">
                  <label for="primeroFuncionario" class="form-label" aria-label="primeroFuncionario">
                    NOMBRE FUNCIONARIO EN EL ENCARTE OFICIAL*</label
                  >
                  <input
                    type="text"
                    id="primeroFuncionario"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoSegundo[i].nombreEncarte"
                    aria-label="Nombre Funcionario"
                  />
                </div>

                <div class="mb-3 col-lg-4 col-xs-12">
                  <label for="primeroReportado" class="form-label" aria-label="primeroReportado">
                    NOMBRE FUNCIONARIO REPORTADO*</label
                  >
                  <input
                    type="text"
                    id="primeroReportado"
                    class="form-control"
                    :class="{ error: $v.form.test.$error }"
                    v-model="form.contenidoSegundo[i].nombreReportado"
                    aria-label="Nombre Funcionario Reportado"
                  />
                </div>
              </div>
              <div class="text-center">
                  <a v-if="contadorEntradasA2>1" class="btn btn-primary my-4" @click="eliminarEntrada(2)">-</a>
                  <a class="btn btn-primary my-4 mx-2" @click="agregarEntrada(2)">+</a>
                </div>
            </div>

          </div>
        </div>

      
      </div>
      


      


    </form>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { required} from "vuelidate/lib/validators";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: ["clickedNext", "currentStep"],
  name: "GeneralForm",
  mounted() {
    // this.getStates();
    /* console.log("mounted", this.$v.$invalid); */
    this.setMunicipios([]);
    if (!this.$v.$invalid) {
      this.$emit("can-continue", { value: true });
    } else {
      // this.verTerminos();
      this.$emit("can-continue", { value: false });
    }
  },
  activated: function () {
    /* console.log("activate"); */
    if (!this.$v.form.$invalid) {
      // console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  data() {
    return {
      form: {
        NumConsejo: "",
        agravioPrimero: "",
        agravioSegundo: "",
        agravioTercero: "",
        agravioCuarto: "",
        agravioQuinto: "",
        agravioSexto: "",
        test: "",
        contenidoPrimero: [
          {
            seccion: "",
            numCasilla: "",
            nombreEncarte: "",
            nombreReportado: "",
          },
        ],
        contenidoSegundo: [
          {
            seccion: "",
            numCasilla: "",
            nombreEncarte: "",
            nombreReportado: "",
          },
        ],
      },
      contadorEntradasA1: 1,
      contadorEntradasA2: 1,
      contadorEntradasA3: 1,
      contadorEntradasA4: 1,
      contadorEntradasA5: 1,
      contadorEntradasA6: 1,
      pocision: 0,
    };
  },
  validations() {
    return {
      form: {
        agravioSegundo:{
        },
        agravioPrimero:{
        },
        agravioTercero:{
        },
        agravioCuarto:{
        },
        agravioQuinto:{
        },
        agravioSexto:{
        },
        test: {},
        contenidoPrimero:{required},
        contenidoSegundo:{ },
      },
    };
  },
  computed: {
    ...mapState("generals", [
      "tipoDocData",
      "generosData",
      "ciudades",
      "estados",
    ]),
    estadoSeleccionado() {
      return this.form.estado;
    },
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
    ...mapMutations("generals", ["setMunicipios"]),
    ...mapActions({
      getStates: "generals/getStates",
      getMunicipios: "generals/getMunicipios",
    }),

    agregarEntrada(id){
      switch(id){
        case 1:
          this.contadorEntradasA1++;
          this.form.contenidoPrimero.push({
            seccion: "",
            numCasilla: "",
            nombreEncarte: "",
            nombreReportado: "",
          });
          break;
        case 2:
          this.contadorEntradasA2++;
          this.form.contenidoSegundo.push({
            seccion: "",
            numCasilla: "",
            nombreEncarte: "",
            nombreReportado: "",
          });
      }
    },
    eliminarEntrada(id){
      switch(id){
        case 1:
          this.form.contenidoPrimero.splice(this.form.contenidoPrimero.length - 1);
          this.contadorEntradasA1--;
          break;
        case 2:
          this.form.contenidoSegundo.splice(this.form.contenidoSegundo.length - 1);
          this.contadorEntradasA2--;
          break;
      }
    },

    
  },
  watch: {
    /*
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid);
        if (!val.$invalid) {
          this.$emit("can-continue", { value: true });
          this.setFormatoDatos(this.form);
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true, 
    }, */
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
    estadoSeleccionado: {
      handler: function (val) {
        if (val) {
          this.form.ciudad = null;
          console.log(val.id);
          this.getMunicipios(val.id - 1);
        }
      },
    },
  },
};
</script>
<style>
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.vs__dropdown-toggle {
  border: 1px solid #eee;
  height: calc(1.5em + 0.75rem + 2px);
}
.v-select {
  height: calc(1.5em + 0.75rem + 2px);
  box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>